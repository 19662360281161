import { Layout, Table, Form, Col, Row, Button, Space, Checkbox, Modal } from 'antd';
import { useGetProjectDrawingsQuery } from '../features/api/apiSlice';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import EditDrawingForm from './DrawingEdit';

import { useDeleteDrawingMutation } from '../features/api/apiSlice';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

export default function Drawings(props) {
  const { projectId } = useParams();

  const { drawingsSelected, setDrawingsSelected } = props;

  const [ deleteDrawing ] = useDeleteDrawingMutation();

  const [ openDrawingEditForm, setOpenDrawingEditForm ] = useState(false);

  const [ formDrawingEdit ] = Form.useForm();
  
  const {
    data: project,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetProjectDrawingsQuery(projectId);

  const handleDrawingEdit = drawing => {
    formDrawingEdit.setFieldsValue(drawing);
    setOpenDrawingEditForm(true);
  }

  const showDeleteConfirm = (drawingId) => {
    confirm({
      title: 'Are you sure delete drawing?',
      icon: <ExclamationCircleFilled />,
      content: 'You cannot undo this action',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'No',
      onOk: async drawingId => {
        try {
          await deleteDrawing({ drawingId, projectId }).unwrap();
        } catch (e) {
          console.error(e)
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  useEffect(() => {
    setDrawingsSelected(project?.drawings.map(({ key }) => key));
  }, [ isLoading ]);

  return (
<Layout>
  <Table
    style={{ width: '100%' }}
    loading={isLoading}
    rowSelection={{
      type: 'checkbox',
      selectedRowKeys: drawingsSelected,
      onChange: (keys, rows) => {
        setDrawingsSelected(keys);
      }
    }}
    columns={[{
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (color) => (<div style={{ width: 10, height: 10, backgroundColor: color }} />),
    }, {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    }, {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    }, {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (created_by, record) => record.email,
    }, {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => dayjs(created_at).format('MMM DD YYYY @ HH:mm'),
    }, {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleDrawingEdit(record)}>Edit</a>
          <Button onClick={() => showDeleteConfirm(record.id)} danger>Delete</Button>
        </Space>
      ),
    }]}
    dataSource={project?.drawings} 
  />
  <EditDrawingForm 
    projectId={projectId}
    form={formDrawingEdit}
    open={openDrawingEditForm} 
    setOpen={setOpenDrawingEditForm}
  />
</Layout>
)}