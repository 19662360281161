import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { ConfigProvider } from 'antd';

import reportWebVitals from './reportWebVitals';

import SignIn from "./pages/auth/SignIn";
import MagicLinkConfirm from "./pages/auth/email/MagicLinkConfirm";
import MagicLinkFollow from "./pages/auth/email/MagicLinkFollow";

import RouteError from "./pages/RouteError";
import AuthRoute from "./routes/AuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProjectRoute from "./routes/ProjectRoute";

import RootRoute from "./routes/RootRoute";
import Projects from "./components/Projects";

import store from './store'
import { Provider } from 'react-redux'

import "./index.css";

const router = createBrowserRouter([{
  path: '/',
  element: <Navigate to="/auth/sign-in" replace />,
}, {
  path: '/auth/sign-in',
  element: <AuthRoute><SignIn/></AuthRoute>,
  errorElement: <RouteError/>,
}, {
  path: '/auth/email/magic-link-confirm',
  element: <AuthRoute><MagicLinkConfirm/></AuthRoute>,
  errorElement: <RouteError/>,
}, {
  path: '/auth/email/magic-link-follow',
  element: <AuthRoute><MagicLinkFollow/></AuthRoute>,
  errorElement: <RouteError/>,
}, {
  path: '/projects',
  element: <ProtectedRoute><RootRoute menuSelectedKey="projects" /></ProtectedRoute>,
  children: [{
    path: "/projects",
    element: <Projects/>,
  }]
}, {
  path: '/projects/:projectId/:tabKey',
  element: 
    <ProtectedRoute>
      <ProjectRoute menuSelectedKey="projects" />
    </ProtectedRoute>,
  errorElement: <RouteError/>,
}])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: '#ffffff',
            // headerHeight: 126,
          },
        }
      }}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
