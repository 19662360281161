import { Modal, Form, Input, Select, ColorPicker } from "antd";
import { usePostProjectDrawingIdMutation } from '../features/api/apiSlice'

export default function EditDrawingForm (props) {
  const { open, setOpen, form, projectId } = props;
  const [ postProjectDrawingId, { isLoading }] = usePostProjectDrawingIdMutation()

  const handleCreate = () => {
    form
      .validateFields()
      .then(async values => {
        const { id, name, type, color } = values;
        form.resetFields();

        const data = await postProjectDrawingId({ id, name, type, color, projectId }).unwrap();
        setOpen(false);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={open}
      title="Drawing"
      okText="Update"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      onOk={handleCreate}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="id"
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ 
            required: true, 
            message: "Please input the name of the drawing" 
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select
            mode="single"
            placeholder="Please select drawing type"
            name="select-type"
          >
            <Select.Option value="Layer">Layer</Select.Option>
            <Select.Option value="DP">Distribution Points</Select.Option>
            <Select.Option value="DPArea">DP Areas</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="color" label="Color">
          <ColorPicker showText disabledAlpha/>
        </Form.Item>
      </Form>
    </Modal>
  );
};