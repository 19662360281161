// https://www.robinwieruch.de/react-router-private-routes/
import { Navigate } from "react-router-dom";
import { auth } from '../firebase';
import { useState, useEffect } from 'react'

export default function AuthRoute({ children }) {
  const [ loading, setLoading ] = useState(true)
  const [ user, setUser ] = useState()

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser(user)
      setLoading(false)
    });  
  })

  return loading 
    ? <></> 
    : user
      ? <Navigate to="/projects" replace />
      : children;
};