import { Tabs, Dropdown, Button, Form } from 'antd';
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegMap } from "react-icons/fa6";
import { FaDrawPolygon, FaMapMarkerAlt } from "react-icons/fa";
import { DownOutlined, InfoCircleOutlined, UsergroupAddOutlined, NodeIndexOutlined } from '@ant-design/icons';

import ProjectMap from './ProjectMap';

import Access from './Access';
import AccessInvite from './AccessInvite';

import Surveys from './Surveys';
import Vectors from './Vectors';

import Drawings from './Drawings';
import AddDrawingsForm from './DrawingsAdd';

import fileDownload from 'js-file-download';
import { useExportSurveysMutation, useExportVectorsMutation } from '../features/api/apiSlice';

const downloadBlob = (content, filename, contentType) => {
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export default function ProjectTabs(props) {
  const { projectId, tabKey } = useParams();

  const navigate = useNavigate();
  const [ exportSurveys ] = useExportSurveysMutation();
  const [ exportVectors ] = useExportVectorsMutation();

  const [ drawingsSelected, setDrawingsSelected ] = useState([]);
  const [ surveysSelected, setSurveysSelected ] = useState([]);
  const [ vectorsSelected, setVectorsSelected ] = useState([]);

  const [ openDrawingsAddForm, setOpenDrawingsAddForm ] = useState(false);
  const [ formDrawingsAdd ] = Form.useForm();

  const [ openAccessInvite, setOpenAccessInvite ] = useState(false);
  const [ formAccessInvite ] = Form.useForm();

  const exportVectorsSelected = async e => {
    const { data } = await exportVectors({ drawingsIds: vectorsSelected.map(v => v.drawing_id), projectId });
    fileDownload(data, `export-vectors.zip`);
  }

  const exportSurveysSelected = async e => {
    switch (e.key) {
      case 'points-shape':
        const { data } = await exportSurveys({ surveyIds: surveysSelected.map(s => s.id), projectId });
        fileDownload(data, `export.zip`);
        break;
      case 'points-csv':
        const csv = [[
          'id',
          'longitude',
          'latitude',
          'depth',
          'photo_url',
          'drawing_name',
          'created_at',
        ]].concat(surveysSelected.map(s => [
          s.id, 
          s.longitude, 
          s.latitude, 
          s.depth, 
          `https://ucarecdn.com/${s.uuid}/`, 
          s.drawing_name, 
          s.created_at
        ].map(k => `"${k}"`).join(','))).join("\n")

        downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;');
        break;
      default:
        break;
    }
  }

  return (
<Tabs
  tabBarStyle={{ backgroundColor: 'white', padding: '0 16px 0 16px' }}
  activeKey={tabKey}
  tabBarExtraContent={
    (tabKey == 'surveys' && 
      <Dropdown
        trigger={['click']}
        menu={{
          onClick: exportSurveysSelected,  
          items: [{
            label: 'Points in Shape file format (.shp)',
            key: 'points-shape',
          }, {
            label: 'Points in CSV',
            key: 'points-csv',
          }]}}
      ><a onClick={(e) => e.preventDefault()}>
        <Button disabled={0 == surveysSelected?.length}>Export<DownOutlined /></Button>
      </a>
      </Dropdown> )
    || (tabKey == 'vectors' &&
        <Button 
          onClick={exportVectorsSelected} 
          disabled={0 == vectorsSelected?.length}
        >Export</Button>)
    || (tabKey == 'drawings' && <Button 
          onClick={() => setOpenDrawingsAddForm(true)}
        >Add Drawings</Button>)
    || (tabKey == 'access' && <Button 
          onClick={() => setOpenAccessInvite(true)}
        >Invite</Button>)
  }
  onTabClick={(key, e) => {
    navigate(`/projects/${projectId}/${key}`)
  }}
  items={[{
    key: 'map',
    label: 'Map',
    icon: <FaRegMap size={16} style={{
      verticalAlign: 'middle',
      marginTop: '-2px',
    }} />,
    children: <ProjectMap
      drawingsSelected={drawingsSelected}
    />
  }, {
    key: 'drawings',
    label: 'Drawings',
    icon: <FaDrawPolygon size={16} style={{
      verticalAlign: 'middle',
      marginTop: '-2px',
    }} />,
    children: 
      <div>
        <Drawings 
          drawingsSelected={drawingsSelected}
          setDrawingsSelected={setDrawingsSelected}
        />
        <AddDrawingsForm 
          projectId={projectId}
          form={formDrawingsAdd}
          open={openDrawingsAddForm} 
          setOpen={setOpenDrawingsAddForm}
        />
      </div>,
  }, {
    key: 'surveys',
    label: 'Points',
    icon: <FaMapMarkerAlt size={16} style={{
      verticalAlign: 'middle',
      marginTop: '-2px',
    }} />,
    children: <Surveys 
      setSurveysSelected={setSurveysSelected}
    />,
  }, {
    key: 'vectors',
    label: 'Surveys',
    icon: <NodeIndexOutlined/>,
    children: <Vectors 
      setVectorsSelected={setVectorsSelected}
    />,
  }, {
    key: 'access',
    label: 'Access',
    icon: <UsergroupAddOutlined />,
    children: 
      <div>
        <Access/>
        <AccessInvite 
          projectId={projectId}
          form={formAccessInvite}
          open={openAccessInvite} 
          setOpen={setOpenAccessInvite}
        />
      </div>,
  }, {
    key: 'about',
    label: 'About',
    icon: <InfoCircleOutlined />,
    disabled: true,
  }]}
/>
)}