import { Modal, Form, Select } from "antd";
import { usePostProjectAccessUserIdMutation } from '../features/api/apiSlice'

export default function AccessEdit (props) {
  const { open, setOpen, projectId, form } = props;
  const [ postProjectAccessUserId, { isLoading }] = usePostProjectAccessUserIdMutation();

  const handleEdit = () => {
    form
      .validateFields()
      .then(async values => {
        const { role, user_id } = values;

        await postProjectAccessUserId({ role, projectId, userId: user_id }).unwrap();
        
        setOpen(false);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={open}
      title="Edit Access"
      okText="Confirm"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      onOk={handleEdit}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="role" label="Role">
          <Select
            mode="single"
            placeholder="Please select role"
            name="select-role"
            rules={[{ 
              required: true, 
              message: "Please select role" 
            }]}
          >
            <Select.Option value="ProjectManager">Project Manager</Select.Option>
            <Select.Option value="SiteEngineer">Site Engineer</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};