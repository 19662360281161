import { Modal, Form, Input, Select } from "antd";
import { usePostProjectAccessMutation } from '../features/api/apiSlice'

export default function AccessInvite (props) {
  const { open, setOpen, form, projectId } = props;
  const [ postProjectAccess, { isLoading }] = usePostProjectAccessMutation()

  const handleInvite = () => {
    form
      .validateFields()
      .then(async values => {
        const { role, phone, email } = values;

        await postProjectAccess({ projectId, role, phone, email }).unwrap();
        
        setOpen(false);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={open}
      title="Invite User"
      okText="Confirm"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      onOk={handleInvite}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="role" label="Role">
          <Select
            mode="single"
            placeholder="Please select role"
            name="select-role"
            rules={[{ 
              required: true, 
              message: "Please select role" 
            }]}
          >
            <Select.Option value="ProjectManager">Project Manager</Select.Option>
            <Select.Option value="SiteEngineer">Site Engineer</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input type="textarea" />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  );
};