// https://firebase.google.com/docs/auth/web/email-link-auth?hl=ru
import React, { useState, useEffect } from 'react';

import { auth } from '../../../firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { TbMail } from "react-icons/tb";

import { useNavigate } from 'react-router-dom';
import { Flex, Image, Layout, Button, Form, Input } from 'antd';

export default function MagicLinkConfirm() {
  const navigate = useNavigate();
  const email = window.localStorage.getItem('emailForSignIn');

  useEffect(() => {
    if (!isSignInWithEmailLink(auth, window.location.href))
      navigate('/auth/sign-in')
  })

  const onFinish = values => {
    signInWithEmailLink(auth, values.email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        console.error('signInWithEmailLink', error)
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }

  return (
<Layout style={{ minHeight: '100vh' }}>
  <Flex gap="small" vertical align='center' justify='center' style={{ minHeight: '100vh' }}>
    <Image preview={false} width={100} src="../../InframentIcon.jpg"/>
    <h1>Magic Link Login</h1>
    <Form 
      name="magic-link-login"
      style={{ width: 320 }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      <Form.Item
        name="email"
        initialValue={email}
        rules={[{ 
          required: true, 
          message: 'Please confirm your Email' 
        }]}
      >
        <Input prefix={<TbMail/>} placeholder="Enter Email you used for Magic Link"/>
      </Form.Item>
      <Form.Item>
        <Button 
          style={{ width: '100%' }}
          type="primary" 
          htmlType="submit"
        >
          Confirm
        </Button>
      </Form.Item>
    </Form>
  </Flex>
</Layout>
)}