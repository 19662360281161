// https://www.robinwieruch.de/react-router-private-routes/
import { Navigate } from "react-router-dom";
import { auth } from '../firebase';
import { useState, useEffect } from 'react'

export default function AuthRoute({ children }) {
  const [ loading, setLoading ] = useState(true)
  const [ user, setUser ] = useState()

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true)
          .then(token => {
            setUser(user)
            setLoading(false)
          })
      } else {
        setUser(null)
        setLoading(false)
      }
    });  
  })

  return loading 
    ? <></>
    : user
      ? children
      : <Navigate to="/auth/sign-in" replace />;
};