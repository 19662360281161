import { useNavigate } from 'react-router-dom';
import { Layout, Result, Flex } from 'antd';
import { useEffect } from 'react';

export default function MagicLinkConfirm() {
  const navigate = useNavigate();
  const email = window.localStorage.getItem('emailForSignIn');

  useEffect(() => {
    if (!email) return navigate('/auth/sign-in');
  })

  return (
<Layout style={{ minHeight: '100vh' }}>
  <Flex vertical align='center' justify='center' style={{ minHeight: '100vh' }}>
    <Result
      status="success"
      title="Magic Link is sent"
      subTitle="Check your Inbox and follow the link from the email."
    />
  </Flex>
</Layout>
)}