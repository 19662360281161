import { useState } from 'react';
import { Layout, Menu, Flex, Image } from 'antd';
import { useParams } from "react-router-dom";
import { RiTreasureMapLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

import { LogoutOutlined, PictureOutlined, NumberOutlined } from '@ant-design/icons'

import { useGetProjectQuery } from '../features/api/apiSlice'

import ProjectTabs from '../components/ProjectTabs';

const { Sider, Content, Header } = Layout;

export default function RootRoute(props) {
  const { projectId, tabKey } = useParams();
  const { menuSelectedKey } = props
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const {
    data: project,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetProjectQuery(projectId);

  return (
<Layout hasSider>
  <Sider 
    theme="light" 
    trigger={null} 
    collapsible 
    collapsed={collapsed}
    style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'fixed' }}
  >
    <Flex vertical style={{ height: '100%' }}>
      <Flex vertical flex={'1 1'}>
        <Flex align='center' style={{ padding: '12px', borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)' }}>
          <a target='_blank' href="https://inframent.com" style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
            <Image preview={false} width={40} src="../../InframentIcon.jpg"/>
            <h1>Inframent</h1>
          </a>
        </Flex>
        <Menu
          theme="light"
          defaultSelectedKeys={[ menuSelectedKey ]}
          style={{ height: '100%' }}
          items={[{
            key: 'projects',
            icon: <RiTreasureMapLine size={16} />,
            label: 'Projects',
            onClick: () => navigate(`/projects`),
          }, {
            disabled: true,
            key: 'tags',
            icon: <NumberOutlined />,
            label: 'Tags',
            onClick: () => navigate(`/tags`),
          }]}
        />
      </Flex>
      <Flex vertical flex={'0 1 50px'}>
        <Menu
          theme="light"
          items={[{
              type: 'divider',
            }, {
              key: 'logout',
              icon: <LogoutOutlined />,
              label: 'Log Out',
              onClick: () => {
                auth.signOut().then(console.log).catch(console.error);
              },
            }
          ]}
        />
      </Flex>
    </Flex>
  </Sider>
  <Layout style={{  backgroundColor: 'white', marginLeft: 200 }}>
    <Header>
      <h1 style={{ margin: 0 }}>{project?.title}</h1>
    </Header>
    <ProjectTabs />
  </Layout>
</Layout>
)}