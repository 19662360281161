import { Layout, Table, Button, Image, Space, Modal } from 'antd';
import { useGetSurveysQuery, useDeleteSurveyIdMutation } from '../features/api/apiSlice'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ExclamationCircleFilled } from '@ant-design/icons';

import formatCoords from 'formatcoords';

const { confirm } = Modal;

export default function Surveys(props) {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { setSurveysSelected } = props;

  const [ deleteSurveyId ] = useDeleteSurveyIdMutation();
  
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetSurveysQuery(projectId);

  const showDeleteConfirm = (surveyId) => {
    confirm({
      title: 'Are you sure delete survey photo?',
      icon: <ExclamationCircleFilled />,
      content: 'You cannot undo this action',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteSurveyId({ surveyId, projectId }).unwrap();
        } catch (e) {
          console.error(e)
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  return (
<Layout>
  <Table
    style={{ width: '100%' }}
    loading={isLoading}
    rowSelection={{
      type: 'checkbox',
      onChange: (keys, rows) => {
        setSurveysSelected(keys.map(k => data.find(s => s.id == k)));
      }
    }}
    columns={[{
      title: 'Preview',
      dataIndex: 'uuid',
      key: 'uuid',
      render: uuid => (
        <Image 
          src={`https://ucarecdn.com/${uuid}/-/scale_crop/120x120/center/`}
          preview={{ src: `https://ucarecdn.com/${uuid}/` }}
        />)
    }, {
      title: 'Location',
      key: 'location',
      render: survey => (
        <Image 
          style={{ cursor: "pointer"}}
          onClick={() => navigate(`/projects/${projectId}/map?&pointId=${survey.id}&lng=${survey.longitude}&lat=${survey.latitude}`)}
          preview={false}
          src={`https://api.maptiler.com/maps/nl-cartiqo-topo/static/${survey.longitude},${survey.latitude},18/120x120.png?key=Ha0wpPicSoh3DDh4limA&markers=${survey.longitude},${survey.latitude}`}
        />
      ),
    }, {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    }, {
      title: 'Drawing',
      dataIndex: 'drawing_name',
      key: 'drawing_name',
      filters: isLoading 
        ? []
        : [...new Set(data.map(({ drawing_name }) => drawing_name))]
            .map(val => ({ text: val, value: val })),
      onFilter: (value, record) => value == record.drawing_name,
      // render: layer => 'LEDN-Z1D Vergunning - GardenTrench',
    }, {
      title: 'Depth',
      dataIndex: 'depth',
      key: 'depth',
    }, {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (created_by, record) => record.email,
    }, {
      title: 'Created Date',
      // dataIndex: 'created_at',
      key: 'createdDate',
      render: survey => dayjs(survey.created_at).format('DD MMM YYYY'),
      filters: isLoading 
        ? []
        : [...new Set(data.map(({ created_at }) => dayjs(created_at).format('DD MMM YYYY')))]
            .map(val => ({ text: val, value: val })),
      onFilter: (value, record) => value == dayjs(record.created_at).format('DD MMM YYYY'),
    }, {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showDeleteConfirm(record.id)} danger>Delete</Button>
        </Space>
      ),
    }
  ]}
    dataSource={data} 
  />
</Layout>
)}