import { useRouteError } from "react-router-dom";
import { Layout, Flex } from 'antd';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
<Layout style={{ minHeight: '100vh' }}>
  <Flex gap="small" vertical align='center' justify='center' style={{ minHeight: '100vh' }}>
    <h1>Oops!</h1>
    <p>Sorry, an unexpected error has occurred.</p>
    <p>
      <i>{error.statusText || error.message}</i>
    </p>
  </Flex>
</Layout>
)}