import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Flex, Image, Divider, Layout, Button, Form, Input } from 'antd';
import { GoogleOutlined, AppleFilled } from "@ant-design/icons";
import { TbMail } from "react-icons/tb";

import { auth } from '../../firebase';
import { sendSignInLinkToEmail, signInWithRedirect, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export default function SignIn() {
  const [ loading, setLoading ] = useState(false)

  const navigate = useNavigate();

  const onFinish = values => {
    setLoading(true);
    
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_APP_URI}/auth/email/magic-link-confirm`,
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, values.email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', values.email);
        navigate('/auth/email/magic-link-follow');
      })
      .catch(console.error);
  }

  const onGoogleAuth = values => {
    console.log('onGoogleAuth', values)

    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly')

    signInWithRedirect(auth, provider)
  }
  
  return (
<Layout aria-disabled={'true'} style={{ minHeight: '100vh' }}>
  <Flex gap="small" vertical align='center' justify='center' style={{ minHeight: '100vh' }}>
    <Image preview={false} width={100} src="../InframentIcon.jpg"/>
    <h1>Sign In</h1>
    <div style={{ width: 320, marginBottom: 16 }}>Welcome back to Inframent! Please enter your details below to sign in.</div>
    <Form 
      name="sign-in"
      style={{ width: 320 }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      <Form.Item
        name="email"
        rules={[{ 
          required: true, 
          message: 'Please provide your Email' 
        }]}
      >
        <Input prefix={<TbMail/>} placeholder="Enter your Email"/>
      </Form.Item>
      <Form.Item>
        <Button 
          style={{ width: '100%' }}
          type="primary" 
          htmlType="submit"
          loading={loading}
        >
          Continue with Email
        </Button>
      </Form.Item>
      <Divider plain>OR</Divider>
      <Flex gap="small" vertical>
        <Button 
          style={{ width: '100%' }}
          icon={<GoogleOutlined />}
          className="login-form-button"
          onClick={onGoogleAuth}
        >
          Continue with Gmail
        </Button>
        {/* <Button 
          style={{ width: '100%' }}
          icon={<AppleFilled />}
          className="login-form-button"
        >
          Continue with Apple
        </Button> */}
      </Flex>
    </Form>
  </Flex>
</Layout>
)}